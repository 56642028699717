import { CreateControllerFn, IControllerConfig } from '@wix/yoshi-flow-editor';
import { getGroupFromLocation } from './controllers/helpers';
import { MainController } from './controllers/main/MainController';

import { createController } from './controllers$/controller';

/**
 * Temporary wrapper for legacy & new controller
 * Later will be replaced by only ./controllers$/controller
 */
const _createController: CreateControllerFn = async (ctx) => {
  // For more info about controller structure,
  // check the docs: https://bo.wix.com/pages/yoshi/docs/editor-flow/structure-api/component#controller
  const group = await getGroupFromLocation(ctx);

  const controller = await createController(ctx);
  const legacy = new MainController(ctx, group);

  return {
    async pageReady($w, wixAPI) {
      await Promise.all([
        legacy.pageReady($w, wixAPI),
        controller.pageReady($w, wixAPI),
      ]);
    },

    updateConfig($w, updatedConfig) {
      legacy.updateConfig($w, updatedConfig as IControllerConfig);
      controller.updateConfig?.($w, updatedConfig as IControllerConfig);
    },

    updateAppSettings($w, updates) {
      controller.updateAppSettings?.($w, updates);
    },

    onBeforeUnLoad() {
      legacy.onBeforeUnLoad();
      controller.onBeforeUnLoad?.();
    },

    exports() {
      return {
        ...legacy.exports(),
        ...controller.exports?.(),
      };
    },
  };
};

export default _createController;
