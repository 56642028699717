import { ISocialContentService } from '@wix/social-groups-api';

import * as IFeedTypes from '@wix/ambassador-feed-v1-feed-item/types';
import * as IReactionsTypes from '@wix/ambassador-reactions-v1-identity-reaction/types';
import { IHttpClient } from '@wix/yoshi-flow-editor';

import * as MembersTypes from 'api/members/types';
import * as FeedTypes from 'api/feed/types';
import * as feedApi from 'api/feed';

import { action, flow, observable } from 'mobx';
import { list, object, primitive, serializable, date, raw } from 'serializr';

import { SocialText } from '../../../types/feed';
import { Comment } from './Comment';
import { Attachment } from './Attachment';
import { Reactions } from './Reactions';
import {
  FeedItemActivityType,
  IFeedItem,
  IFeedItemActivity,
  IFeedItemActivityData,
  IFeedItemComments,
  IFeedItemEntity,
  IFeedItemPin,
  IFeedItemRequester,
} from '../../../types/IFeedItem';

class FeedItemActivityData implements IFeedItemActivityData {
  @serializable hasCoverBefore!: boolean;

  @serializable src!: string;

  @serializable content!: string;

  @serializable(list(primitive())) userIds: string[] = [];

  @serializable authorUserId!: string;

  @serializable activity!: string;
}

class FeedItemActivity implements IFeedItemActivity {
  @serializable activityType!: FeedItemActivityType;

  @serializable(object(FeedItemActivityData)) data!: FeedItemActivityData;
}

class FeedItemEntity implements IFeedItemEntity {
  @serializable(object(SocialText)) body: SocialText;
  @serializable(list(raw())) topics: FeedTypes.IFeedItemEntity['topics'];

  constructor(entity: FeedTypes.IFeedItemEntity) {
    this.body = entity.body as SocialText;
    this.topics = entity.topics;
  }
}

class FeedItemPin implements IFeedItemPin {
  @serializable(date()) since!: Date;

  @serializable pinnedBy!: string;

  constructor(since: string, pinnedBy: string) {
    this.since = new Date(since);
    this.pinnedBy = pinnedBy;
  }
}

class FeedItemRequester implements IFeedItemRequester {
  @serializable subscribed!: boolean;
}

class FeedItemComments implements IFeedItemComments {
  @serializable(list(object(Comment)))
  @observable.shallow
  comments: Comment[] = [];

  @observable @serializable total: number = 0;

  @serializable contextToken: string;

  constructor(
    comments: IFeedTypes.Comments = {
      total: 0,
      comments: [],
    },
  ) {
    this.total = comments.total!;
    this.comments = (comments.comments ?? []).map(
      (comment) => new Comment(comment),
    );
    this.contextToken = comments.contextToken!;
  }

  add(comment: IFeedTypes.Comment) {
    this.comments.push(new Comment(comment));
    this.total++;
  }

  remove(commentId: string) {
    this.comments = this.comments.filter((c) => c.commentId !== commentId);
    this.total = this.comments.length;
  }

  setTotal(total: number) {
    this.total = total;
  }
}

export class FeedItem implements IFeedItem {
  @serializable feedItemId: string;

  @serializable(date()) createdAt: Date;

  @serializable(date()) updatedAt: Date;

  @serializable(raw()) createdBy: MembersTypes.IGroupMember;

  @serializable(object(FeedItemEntity)) @observable entity: FeedItemEntity;

  @serializable(object(FeedItemActivity)) activity: FeedItemActivity;

  @serializable(object(FeedItemComments)) comments: FeedItemComments;

  @serializable(object(Reactions)) @observable reactions: any;

  @serializable(list(object(Attachment)))
  @observable.shallow
  attachments: Attachment[] = [];

  @serializable(object(FeedItemPin)) @observable pin?: FeedItemPin;

  @serializable(object(FeedItemRequester))
  @observable
  requesterContext: FeedItemRequester;

  constructor(
    feedItem: FeedTypes.IFeedItem,
    private groupId: string,
    private httpClient: IHttpClient,
  ) {
    this.feedItemId = feedItem.feedItemId!;
    this.createdAt = new Date(feedItem.createdAt!);
    this.updatedAt = new Date(feedItem.updatedAt!);
    this.createdBy = feedItem.createdBy!;

    this.entity = new FeedItemEntity(feedItem.entity);
    this.activity = feedItem.activity as FeedItemActivity;

    this.comments = new FeedItemComments(feedItem.comments!);
    this.reactions = new Reactions(feedItem.reactions!);

    this.attachments = (
      (feedItem.entity && feedItem.entity.attachments) ||
      []
    ).map((attachment) => new Attachment(attachment));

    if (feedItem.pin) {
      this.pin = new FeedItemPin(
        feedItem.pin.since as any,
        feedItem.pin.pinnedBy!,
      );
    }

    this.requesterContext = feedItem.requesterContext as FeedItemRequester;
  }

  @action react = flow(function* (
    this: FeedItem,
    reaction: IReactionsTypes.Reaction,
  ) {
    try {
      const { data: response } = yield this.httpClient.request(
        feedApi.react(this.groupId, this.feedItemId, reaction.reactionCode!),
      );

      this.reactions = new Reactions(response);
    } catch (error) {
      console.error('FeedItem react error: ', error);
    }
  });

  @action unreact = flow(function* (this: FeedItem, reactionCode: string) {
    try {
      const { data: response } = yield this.httpClient.request(
        feedApi.unreact(this.groupId, this.feedItemId, reactionCode),
      );

      this.reactions = new Reactions(response);
    } catch (error) {
      console.error('FeedItem unreact error', error);
    }
  });
  @action
  addUserReaction = flow(function* (
    this: FeedItem,
    userReaction: IReactionsTypes.IdentityReaction,
  ) {
    try {
      this.react(userReaction.reaction!);
      yield;
    } catch (error) {
      console.error('FeedItem.addUserReaction error', error);
    }
  });
  @action
  removeUserReaction = flow(function* (
    this: FeedItem,
    userReaction: IReactionsTypes.IdentityReaction,
  ) {
    try {
      this.unreact(userReaction.reaction?.reactionCode!);
      yield;
    } catch (error) {
      console.error('FeedItem.removeUserReaction error', error);
    }
  });

  @action setEntity = (entity: IFeedItemEntity, updatedAt: Date) => {
    this.entity = entity;
    this.updatedAt = updatedAt;
  };

  @action setCommentsTotal(total: number) {
    this.comments.setTotal(total);
  }

  hasValidActivity(): boolean {
    return (
      this.activity &&
      Object.values(FeedItemActivityType).includes(this.activity.activityType)
    );
  }

  getContent() {
    const content =
      (this.entity && this.entity.body && this.entity.body.content) ||
      (this.activity && this.activity.data && this.activity.data.content);
    return content;
  }
}
